import { Gender } from '@azos/core'
import { QuotationEstimatedPremiumsItem } from '@domain/models'
import { GetProductInfoUseCase } from '@domain/usecases'
import { env } from '@main/config/env'

import api from '../bffApi'

const QuotationsAPI = {
  getProductInfo: async ({
    quotationId,
  }: GetProductInfoUseCase.Params): Promise<GetProductInfoUseCase.Response> =>
    await api
      .get(`/quotations/${quotationId}/product`)
      .then(response => response.data),

  getQuotation: async (
    quotationId: string,
  ): Promise<QuotationEstimatedPremiumsItem[]> =>
    await api
      .get(`/quotations/${quotationId}/estimated-premiums`)
      .then(response => response.data),
}

export default QuotationsAPI
