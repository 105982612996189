import { SWRService, useFetch } from '@azos/core'
import { ListBenefitsUseCase } from '@domain/usecases'
import { makeListBenefitsService } from '@main/factories/services'

const listBenefitsService = makeListBenefitsService()

export const useFetchBenefits = () => {
  const { data = null, error } = useFetch<ListBenefitsUseCase.Response>(
    new SWRService(listBenefitsService.getUrl(), () => {
      return listBenefitsService.execute()
    }),
    { revalidateOnFocus: false },
  )

  const isLoading = !data && !error

  return {
    data,
    isLoading,
    isError: error,
  }
}
