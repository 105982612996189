import { User } from '@azos/account'
import { env } from '@main/config/env'

import api from './api'

export type SetScorePayload = {
  sessionId: string
  proposalId: string
  user: User | null
}

const ClearsaleAPI = {
  setScore: (payload: SetScorePayload) =>
    api.post(`${env.BFF_BASE_URL}/clearsale`, payload),
}

export default ClearsaleAPI
