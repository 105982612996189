import React from 'react'

import { Card, CardAccordion, Typography } from '@Azos-Seguros/apollo'
import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import { ClickPayment } from '@azos/analytics/src/domain/models'
import {
  CheckoutPaymentFrequency,
  ClearsaleExceptionError,
  ClearsaleSessionError,
  PaymentMethod,
} from '@azos/core'
import { Utils as SharedUtils } from '@azos/shared'
import { Button, Radio } from '@azos/shared/src/components/v2'
import { FinalQuotationsItem } from '@data/models'
import {
  BackButton,
  CoverageResume,
} from '@presentation/components/molecules/Upsell'
import { ErrorState } from '@presentation/components/organisms/ErrorState'
import { SelectedCoverage } from '@presentation/providers/CalculatorProvider/CalculatorProvider'
import { useChangeableDueDate } from '@presentation/providers/ChangeableDueDateProvider'
import { useCheckout } from '@presentation/providers/CheckoutProvider'
import { useClearsale } from '@presentation/providers/ClearSaleProvider'
import { useUpsell } from '@presentation/providers/UpsellProvider'
import * as Sentry from '@sentry/nextjs'
import ClearsaleAPI from '@services/clearsale'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { Formik, Form, FormikValues } from 'formik'
import { useRouter } from 'next/router'

import { CheckoutProps, INITIAL_VALUES } from './Checkout.props'
import { Root } from './Checkout.styles'
import { validationSchema } from './Checkout.validation'
import { CreditCardForm, PaymentPeriod } from './components'
import { DueDate } from './components/DueDate'

const Checkout: React.VFC<CheckoutProps> = ({
  creditCardValidator,
  finalQuotations,
  isWebview,
  getAvailableDiscountService,
  proposalId,
  discount,
}) => {
  const { user } = useAuth()
  const { setSection } = useUpsell()
  const { sessionId } = useClearsale()

  const {
    setExpiresAt,
    setNextDueDate,
    paymentFrequency,
    setPaymentFrequency,
    setDiscount,
  } = useCheckout()
  const router = useRouter()

  const { isChangeableDueDateActive } = useChangeableDueDate()

  const [isCreditCardOpen, setIsCreditCardOpen] = React.useState(true)
  const [isPixCardOpen, setIsPixCardOpen] = React.useState(false)

  const [paymentMethod, setPaymentMethod] = React.useState(
    INITIAL_VALUES.paymentMethod,
  )

  const isAnnualPaymentAvailable = React.useMemo(
    () =>
      finalQuotations?.allowedFrequency?.includes(
        CheckoutPaymentFrequency.ANNUAL,
      ),
    [finalQuotations?.allowedFrequency],
  )

  const onHandleAccordion = (type: PaymentMethod, open: boolean) => {
    const paymentCases: { [key: string]: () => void } = {
      [PaymentMethod.CREDIT_CARD]: () => {
        setIsCreditCardOpen(open)
        setIsPixCardOpen(false)
      },
      [PaymentMethod.PIX]: () => {
        setIsPixCardOpen(open)
        setIsCreditCardOpen(false)
      },
    }

    if (paymentCases[type]) return paymentCases[type]()
  }

  const formatCoverageResume = (
    finalQuotation: FinalQuotationsItem,
  ): SelectedCoverage => ({
    code: finalQuotation.code,
    info: finalQuotation.info,
    data: {
      price: finalQuotation.finalQuotation.premium,
      premium: finalQuotation.finalQuotation.coveredCapital,
      premiumValue: finalQuotation.finalQuotation.coveredCapital,
    },
  })

  const isError = React.useMemo(() => !finalQuotations, [finalQuotations])

  React.useEffect(() => {
    setSection(4)
    analytics.dashboard.upsell.checkout.viewCheckoutPage.execute()
  }, [setSection])

  const handleSubmit = React.useCallback(
    (values: FormikValues) => {
      const paymentMethodCases: { [key: string]: () => void } = {
        [PaymentMethod.CREDIT_CARD]: () => {
          router.push(
            {
              pathname: '/simular-novo-seguro/pagamento/cartao-credito',
              query: {
                creditcard: JSON.stringify(values),
              },
            },
            '/simular-novo-seguro/pagamento/cartao-credito',
          )
        },
        [PaymentMethod.PIX]: () =>
          router.push('/simular-novo-seguro/pagamento/pix'),
      }

      if (paymentMethodCases[values.paymentMethod]) {
        return paymentMethodCases[values.paymentMethod]()
      }
    },
    [router],
  )

  const handleChangePayment = (value: PaymentMethod) => {
    const paymentCases: { [key: string]: ClickPayment } = {
      [PaymentMethod.CREDIT_CARD]: ClickPayment.CREDIT_CARD,
      [PaymentMethod.PIX]: ClickPayment.PIX,
    }

    if (paymentCases[value]) {
      analytics.dashboard.upsell.checkout.clickPaymentType.execute({
        type: paymentCases[value],
      })
    }
  }

  const handleExpand = () => {
    analytics.dashboard.upsell.checkout.expandCheckoutDetails.execute()
  }

  const onSelectDueDate = (date: string, formattedNextDueDate: string) => {
    analytics.dashboard.upsell.checkout.saveDueDateChange.execute()

    setExpiresAt(date)
    setNextDueDate(formattedNextDueDate)
  }

  React.useEffect(() => {
    const startClearSaleAnalysis = async () => {
      const timeBeforeCall = dayjs()

      if (sessionId && user && !SharedUtils.object.isEmpty(user)) {
        await ClearsaleAPI.setScore({
          user,
          sessionId: sessionId,
          proposalId: proposalId || '',
        })
          .then(() => {
            const timeAfterCall = dayjs()

            const timeDifference = Number(
              dayjs(timeAfterCall.diff(timeBeforeCall)).format('ss.SSS'),
            )

            analytics.dashboard.upsell.checkout.clearSaleResponseTime.execute({
              response_time: timeDifference,
            })
          })
          .catch(() => {
            Sentry.captureException(new ClearsaleExceptionError())
          })
      } else {
        Sentry.setUser({ email: user?.email })
        Sentry.captureException(new ClearsaleSessionError())
      }
    }

    startClearSaleAnalysis()
  }, [sessionId, proposalId, user])

  React.useEffect(() => {
    if (
      paymentFrequency === CheckoutPaymentFrequency.ANNUAL &&
      !isAnnualPaymentAvailable
    ) {
      setPaymentFrequency(CheckoutPaymentFrequency.MONTHLY)
    }
  }, [isAnnualPaymentAvailable, paymentFrequency, setPaymentFrequency])

  React.useEffect(() => {
    if (paymentMethod) {
      const selectedDiscount = getAvailableDiscountService({
        frequency: paymentFrequency,
        method: paymentMethod,
      })
      setDiscount(selectedDiscount)
    }
  }, [
    getAvailableDiscountService,
    paymentFrequency,
    paymentMethod,
    setDiscount,
  ])

  const currentDiscount = React.useMemo(
    () =>
      getAvailableDiscountService({
        frequency: paymentFrequency,
        method: paymentMethod,
      }),
    [getAvailableDiscountService, paymentFrequency, paymentMethod],
  )

  return (
    <Root>
      <ErrorState isError={isError}>
        <Formik
          initialValues={INITIAL_VALUES}
          onSubmit={handleSubmit}
          validationSchema={validationSchema(creditCardValidator)}
          validateOnMount
        >
          {({ setFieldValue, isSubmitting, isValid, values }) => (
            <>
              <div className="checkout__header">
                <BackButton
                  text="Editar pedido"
                  className="checkout__back-button"
                />

                {isAnnualPaymentAvailable && (
                  <Typography variant="bodyBold">
                    Escolha o intervalo do seu pagamento:
                  </Typography>
                )}
              </div>

              <div className="checkout__wrapper">
                <div className="checkout__wrapper-content">
                  <Form className="checkout__form">
                    {isAnnualPaymentAvailable && (
                      <PaymentPeriod
                        value={paymentFrequency}
                        onChange={frequency => setPaymentFrequency(frequency)}
                      />
                    )}

                    <Typography variant="bodyBold" className="checkout__title">
                      Qual será a forma de pagamento?
                    </Typography>

                    <Card>
                      <div
                        onClick={() => {
                          setFieldValue(
                            'paymentMethod',
                            PaymentMethod.CREDIT_CARD,
                            true,
                          )
                          setPaymentMethod(PaymentMethod.CREDIT_CARD)
                          onHandleAccordion(
                            PaymentMethod.CREDIT_CARD,
                            !isCreditCardOpen,
                          )
                          handleChangePayment(PaymentMethod.CREDIT_CARD)
                        }}
                        className="checkout__card--header"
                      >
                        <div>
                          <i
                            className={clsx('icon-credit-card', {
                              ['active']: isCreditCardOpen,
                            })}
                          />
                          <Typography variant="bodyBold2">
                            Cartão de crédito
                          </Typography>
                        </div>

                        <Radio checked={isCreditCardOpen} />
                      </div>
                      <CardAccordion open={isCreditCardOpen}>
                        <hr className="checkout__divider" />
                        <CreditCardForm />

                        <Button
                          fullWidth
                          className="checkout__button-credit-card"
                          disabled={isSubmitting || !isValid}
                        >
                          Finalizar o pedido
                        </Button>
                      </CardAccordion>
                    </Card>

                    <Card>
                      <div
                        onClick={() => {
                          setFieldValue(
                            'paymentMethod',
                            PaymentMethod.PIX,
                            true,
                          )
                          setPaymentMethod(PaymentMethod.PIX)
                          onHandleAccordion(PaymentMethod.PIX, !isPixCardOpen)
                          handleChangePayment(PaymentMethod.PIX)
                        }}
                        className="checkout__card--header"
                      >
                        <div>
                          <i
                            className={clsx('icon-pix', {
                              ['active']: isPixCardOpen,
                            })}
                          />
                          <Typography variant="bodyBold2">PIX</Typography>
                        </div>

                        <Radio checked={isPixCardOpen} />
                      </div>
                    </Card>
                  </Form>

                  {isChangeableDueDateActive &&
                    paymentFrequency !== CheckoutPaymentFrequency.ANNUAL && (
                      <DueDate
                        proposalId={proposalId}
                        onSubmit={onSelectDueDate}
                      />
                    )}
                </div>
                <CoverageResume
                  className="checkout__coverage-resume"
                  coverages={
                    finalQuotations?.finalQuotations.map(
                      formatCoverageResume,
                    ) ?? []
                  }
                  total={finalQuotations?.finalPremium ?? 0}
                  annualPremium={finalQuotations?.annualPremium ?? 0}
                  paymentFrequency={paymentFrequency}
                  paymentMethod={paymentMethod}
                  onExpand={handleExpand}
                  showButton={isPixCardOpen}
                  buttonText="Continuar"
                  onButtonClick={() => handleSubmit(values)}
                  isWebview={isWebview}
                  discount={currentDiscount}
                  allDiscounts={discount?.discounts || []}
                />
              </div>
            </>
          )}
        </Formik>
      </ErrorState>
    </Root>
  )
}

export default Checkout
