import React from 'react'

import { Tooltip, Typography, useTimeout } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import {
  CheckoutPaymentFrequency,
  CoveragesCode,
  Utils as ClientsUtils,
} from '@azos/core'
import { Coverage2Premium } from '@data/models'
import { GetProductInfoUseCase } from '@domain/usecases'
import {
  getCoverageIcon,
  getCoverageSource,
  getSelectedCoverage,
} from '@main/config/coverages'
import {
  BackButton,
  CoverageResume,
} from '@presentation/components/molecules/Upsell'
import { ErrorState } from '@presentation/components/organisms/ErrorState'
import { useCalculator } from '@presentation/providers/CalculatorProvider'
import { SelectedCoverage } from '@presentation/providers/CalculatorProvider/CalculatorProvider'
import { useRouter } from '@presentation/providers/RouterProvider'
import { useUpsell } from '@presentation/providers/UpsellProvider'
import QuotationsAPI from '@services/quotations'
import clsx from 'clsx'

import { CalculatorProps, UpdateCoverage } from './Calculator.props'
import { Root } from './Calculator.styles'
import {
  CardProduct,
  CardProductData,
  RefusedCoverages,
  UnselectedCoverages,
} from './components'

const ADD_TO_CART_DELAY = 750

const Calculator: React.VFC<CalculatorProps> = ({
  coverages2premiums,
  updateQuotationService,
  quotationId,
  isWebview,
}) => {
  const { setSection } = useUpsell()
  const {
    coverages,
    refusedCoverages,
    setCoverages,
    selectedProducts,
    selectedCoverages,
    selectedCoveragesList,
    unselectedCoveragesList,
    getCollection,
    selectCoverage,
    removeCoverage,
    total,
  } = useCalculator()
  const router = useRouter()

  const [disabled, setDisabled] = React.useState(false)
  const [productInfo, setProductInfo] =
    React.useState<GetProductInfoUseCase.Response | null>(null)

  const isError = React.useMemo<boolean>(
    () => !coverages2premiums,
    [coverages2premiums],
  )

  useTimeout(() => {
    const hasLifeInsuranceListed = unselectedCoveragesList.find(item =>
      ClientsUtils.coverages.isDeathCoverage(item.code),
    )
    if (selectedProducts.length === 0) {
      if (hasLifeInsuranceListed)
        selectCoverage(CoveragesCode.COD_1001_SEGURO_DE_VIDA)
      else if ((unselectedCoveragesList?.length as number) > 0)
        selectCoverage(unselectedCoveragesList?.[0].code as CoveragesCode)
    }
  }, ADD_TO_CART_DELAY)

  React.useEffect(() => {
    if (quotationId) {
      QuotationsAPI.getProductInfo({ quotationId }).then(response =>
        setProductInfo(response),
      )
    }
  }, [quotationId])

  React.useEffect(() => {
    setSection(2)
    if (coverages2premiums) {
      setCoverages(coverages2premiums)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverages2premiums, setSection])

  React.useEffect(() => {
    analytics.dashboard.upsell.calculator.viewCalculatorPage.execute()
  }, [])

  const handleChangeCoverage = React.useCallback(
    (coverage: Coverage2Premium) => (value: CardProductData) => {
      const { premium, price } = value
      selectCoverage(coverage.code, { price, premium, premiumValue: premium })
    },
    [selectCoverage],
  )

  const handleChangeCommittedCoverage = React.useCallback(
    (coverage: Coverage2Premium) => (value: CardProductData) => {
      const { price } = value

      if (price) {
        analytics.dashboard.upsell.calculator.changeCoverageRange.execute({
          type: coverage.info.name,
          finalPrice: price,
        })
      }
    },
    [],
  )

  const handleSelectCoverage = React.useCallback(
    (coverage: Coverage2Premium) => {
      selectCoverage(coverage.code)

      analytics.dashboard.upsell.calculator.addCoverageProduct.execute({
        type: coverage.info.name,
      })
    },
    [selectCoverage],
  )

  const handleRemoveCoverage = React.useCallback(
    (code: CoveragesCode) => {
      removeCoverage(code)
    },
    [removeCoverage],
  )

  const formatToCoverage = (coverage: SelectedCoverage): UpdateCoverage => {
    return {
      code: coverage.code,
      price: coverage.data.price,
      premium: coverage.data.premiumValue,
    }
  }

  const handleSaveCoverages = async () => {
    setDisabled(true)

    analytics.dashboard.upsell.calculator.clickSubmitCalculator.execute()

    if (quotationId) {
      try {
        await updateQuotationService.execute({
          quotationId,
          coverages: selectedCoverages.map(formatToCoverage),
        })
        await router.push('/simular-novo-seguro/dps')
      } catch (err) {
        setDisabled(false)
      }
    }
  }

  const handleGoBack = () => {
    analytics.dashboard.upsell.calculator.goBackCalculator.execute()

    router.push('/simular-novo-seguro/simulacao')
  }

  const handleExpandDetails = () => {
    analytics.dashboard.upsell.calculator.clickDetailsCoverage.execute()
    analytics.dashboard.upsell.calculator.expandCoverageDetails.execute()
  }

  const productNameFormatted = productInfo?.productName
    ?.toLowerCase()
    .replace(/(?:^|\s)\S/g, function (letter) {
      return letter.toUpperCase()
    })

  return (
    <Root>
      <ErrorState isError={isError}>
        <div className="calculator__wrapper">
          <div className="calculator__wrapper--content">
            <BackButton
              className="calculator__back-button"
              onClick={handleGoBack}
            />
            {selectedCoveragesList.length > 0 && (
              <>
                <Typography
                  variant="bodyBold"
                  className="calculator__selected-coverages--title"
                >
                  Personalize as coberturas do seu jeito
                </Typography>
                {productInfo && (
                  <Typography
                    variant="body3"
                    className="calculator__selected-coverages--product"
                  >
                    <Tooltip
                      maxWidth="240px"
                      className="calculator__selected-coverages--product__tooltip"
                      position="left"
                      title={`Essa contratação atende às Condições Gerais do Produto: ${productNameFormatted} (SUSEP: ${productInfo.susepProcessNumber})`}
                    >
                      <i className="icon-circle-information" />
                    </Tooltip>
                    {`Produto ${productNameFormatted} (SUSEP:  ${productInfo.susepProcessNumber})`}
                  </Typography>
                )}
                {selectedCoveragesList.map(coverage => {
                  const code = coverage.code
                  const collection = getCollection(code)
                  const icon = getCoverageIcon(coverage.code)
                  const active = selectedProducts.includes(code)
                  const changeCoverage = handleChangeCoverage(coverage)
                  const commitCoverage = handleChangeCommittedCoverage(coverage)

                  return (
                    <CardProduct
                      key={coverage.code}
                      code={coverage.code}
                      onToggle={handleRemoveCoverage}
                      icon={icon}
                      name={coverage.info.name}
                      description={coverage.info.description}
                      active={active}
                      disabled={disabled}
                      source={getCoverageSource(collection)}
                      value={getSelectedCoverage(
                        collection,
                        selectedCoverages,
                        coverage.code,
                      )}
                      onChange={changeCoverage}
                      onChangeCommitted={commitCoverage}
                    ></CardProduct>
                  )
                })}
              </>
            )}

            {unselectedCoveragesList.length > 0 && (
              <UnselectedCoverages
                coverages={unselectedCoveragesList}
                totalCoveragesSelected={selectedCoveragesList.length}
                onSelectCoverage={handleSelectCoverage}
                className={clsx('calculator__unselected-coverages', {
                  ['hasPrevious']: selectedCoveragesList.length > 0,
                })}
              />
            )}

            {refusedCoverages.length > 0 && (
              <RefusedCoverages coverages={refusedCoverages} />
            )}
          </div>

          <CoverageResume
            coverages={selectedCoverages}
            total={total}
            onButtonClick={handleSaveCoverages}
            onExpand={handleExpandDetails}
            isButtonDisabled={selectedCoverages.length === 0}
            isWebview={isWebview}
            paymentFrequency={CheckoutPaymentFrequency.MONTHLY}
          />
        </div>
      </ErrorState>
    </Root>
  )
}

export default Calculator
