import React from 'react'

import analytics from '@azos/analytics'
import { useMediaQuery } from '@azos/shared'
import { Text } from '@azos/shared/src/components/v2'
import { MENU } from '@main/config/menu'
import CardBenefits from '@presentation/components/molecules/CardBenefits'
import TemplateManager from '@presentation/components/templates/TemplateManager'
import { useFetchBenefits } from '@presentation/hooks/useFetchBenefits'

import Root, { BenefitsDivider } from './Benefits.styles'

const Benefits = () => {
  const { data } = useFetchBenefits()
  const { isMobile } = useMediaQuery()

  React.useEffect(() => {
    analytics.dashboard.benefits.view.benefitsPageView.execute()
  }, [])

  return (
    <TemplateManager overflowMain navigationProps={{ value: MENU.BENEFICIOS }}>
      <Root>
        <Text variant="h5" className="benefits__title">
          Benefícios Azos
        </Text>
        <Text className="benefits__description">
          Quem faz parte da comunidade Azos possui benefícios em vida.
          Aproveite!
        </Text>
        {isMobile && <BenefitsDivider />}
        {data?.map(dataBenefit => (
          <CardBenefits key={dataBenefit.id} {...dataBenefit} />
        ))}
      </Root>
    </TemplateManager>
  )
}

export default Benefits
