import React from 'react'

import { useAuth, SignUpV2 } from '@azos/account'
import { NoSSR } from '@azos/shared'
import { Header } from '@azos/shared/src/components/v2'
import { useRouter } from 'next/router'

import { Root } from './Register.styles'

const RegisterPage = () => {
  const { query } = useRouter()
  const { setFrom } = useAuth()

  React.useEffect(() => {
    if (query.origin) {
      setFrom(query.origin.toString())
    }
  }, [query, setFrom])

  return (
    <NoSSR>
      <Root>
        <Header hiddenSignIn />

        <main>
          <div className="card">
            <SignUpV2 />
          </div>
        </main>
      </Root>
    </NoSSR>
  )
}

export default RegisterPage
